<template>
  <div class="keypad">
    <input
      :class="{ 'form-control keypad-input': true, 'is-error': isError }"
      type="tel"
      name="number"
      v-number
      v-model="number"
    />
    <div class="keypad-key">
      <ul class="keypad-num">
        <li class="keypad-num-item"><button class="keypad-num-btn" type="button" @click="keypad('7')">7</button></li>
        <li class="keypad-num-item"><button class="keypad-num-btn" type="button" @click="keypad('8')">8</button></li>
        <li class="keypad-num-item"><button class="keypad-num-btn" type="button" @click="keypad('9')">9</button></li>
        <li class="keypad-num-item"><button class="keypad-num-btn" type="button" @click="keypad('4')">4</button></li>
        <li class="keypad-num-item"><button class="keypad-num-btn" type="button" @click="keypad('5')">5</button></li>
        <li class="keypad-num-item"><button class="keypad-num-btn" type="button" @click="keypad('6')">6</button></li>
        <li class="keypad-num-item"><button class="keypad-num-btn" type="button" @click="keypad('1')">1</button></li>
        <li class="keypad-num-item"><button class="keypad-num-btn" type="button" @click="keypad('2')">2</button></li>
        <li class="keypad-num-item"><button class="keypad-num-btn" type="button" @click="keypad('3')">3</button></li>
        <li class="keypad-num-item"><button class="keypad-num-btn" type="button" @click="keypad('0')">0</button></li>
        <li class="keypad-num-item keypad-num-col2"><button class="keypad-num-btn" type="button" @click="keypad('00')">00</button></li>
      </ul>
      <ul class="keypad-delete">
        <li class="keypad-delete-item">
          <button class="keypad-delete-btn keypad-delete-back" type="button" @click="back"><i class="aikon aikon-arrow_left"></i></button>
        </li>
        <li class="keypad-delete-item"><button class="keypad-delete-btn keypad-delete-clear" type="button" @click="clear">C</button></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      number: 0,
    };
  },
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    keypad: function(key) {
      const oldNum = this.number;
      const newNum = ((oldNum > 0)? oldNum : '') + key;
      if (newNum === String(Number(newNum)))
        this.number = Number(newNum);
    },
    back: function() {
      const oldNum = String(this.number);
      const newNum = oldNum.slice(0, -1);
      if (newNum === String(Number(newNum))) 
        this.number = Number(newNum);
    },
    clear: function() {
      this.number = 0;
    },
  },
};
</script>
