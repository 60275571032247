<template>
  <div>
    <Modal @close="handeClearModalAndDataForm()" v-if="manualPointModal">
      <template v-slot:headline>{{ isPaymentType ? 'ポイントを消費' : 'ポイントを付与' }}</template>
      <template v-slot:body>
        <FormRow>
          <template v-slot:label>種別を選択</template>
          <template v-slot:content>
            <div class="form-content-row">
              <ul class="listGroup">
                <li class="listGroup-item text-red pt-0 pb-0 bd-0" v-if="!isPaymentType">最大付与ポイントは999,999まで</li>
                <li v-for="item in typeData" :key="item.index" class="listGroup-item">
                  <label class="form-radio">
                    <input
                      :class="{ 'form-radio-input': true, 'is-error': errors.type }"
                      type="radio"
                      name="type"
                      :value="item.value"
                      v-model="form.type"
                      @change="onChangeType"
                    />
                    <span class="form-radio-label">{{ item.label }}</span>
                  </label>
                  <template v-if="item.value === storeManualType">
                    <SearchSelect
                      ref="searchSelectShop"
                      singleLabel="name"
                      :isError="errors.shopId"
                      :disabled="checkStoreOrCancel(item)"
                      :options="shopList"
                      :multiple="false"
                      :closeOnSelect="true"
                      :searchKeyMinLength="searchKeyLength"
                      :searchAction="searchAction"
                      :resetOptions="resetOptions"
                      :params="params"
                      :searchField="searchField"
                      @change-selection="handleSelectedShop"
                    />
                  </template>
                  <template v-if="item.value === cancelPointStoreType">
                    <SearchSelect
                      ref="searchSelectCancelShop"
                      singleLabel="name"
                      :isError="errors.cancelShopId"
                      :disabled="checkStoreOrCancel(item)"
                      :options="shopList"
                      :multiple="false"
                      :closeOnSelect="true"
                      :searchKeyMinLength="searchKeyLength"
                      :searchAction="searchAction"
                      :resetOptions="resetOptions"
                      :params="params"
                      :searchField="searchField"
                      @change-selection="handleSelectedShop"
                    />
                  </template>
                </li>
              </ul>
            </div>
            <div class="form-content-row">
              <NumericKeypad ref="keypad" :isError="errors.added || errors.amount" />
            </div>
          </template>
        </FormRow>
      </template>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <button class="btn btn-white" type="button" @click="handeClearModalAndDataForm">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn"
              :class="{ 'btn-red': isPaymentType, 'btn-main': !isPaymentType }"
              :handle-submit="submit"
              :button-text="isPaymentType ? '消費する' : '付与する'"
            />
          </li>
        </ul>
      </template>
    </Modal>
    <Modal @close="closeModal('checkManualPointModal')" v-if="checkManualPointModal">
      <template v-slot:headline>{{ isPaymentType ? 'ポイント消費の確認' : 'ポイント付与の確認' }}</template>
      <template v-slot:body>
        <p class="description text-align-center">
          {{totalUserSelected}}{{ isPaymentType ? '件消費しますがよろしいですか？' : '件付与しますがよろしいですか？'}}
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-white" type="button" @click="handeClearModalAndDataForm">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn"
              :class="{ 'btn-red': isPaymentType, 'btn-main': !isPaymentType }"
              :handle-submit="handleManual"
              :button-text="isPaymentType ? '消費する' : '付与する'"
            />
          </li>
        </ul>
      </template>
    </Modal>
  </div>

  </template>

  <script>
  import { mapGetters } from 'vuex';
  import { required, between } from 'vuelidate/lib/validators';
  //components
  import FormRow from '@/components/FormRow.vue';
  import NumericKeypad from '@/components/NumericKeypad.vue';
  import SearchSelect from '@/components/SearchSelect.vue';
  import Modal from '@/components/Modal.vue';
  //mixins
  import modal from '@/mixins/plugin/modal';
  import search from '@/mixins/plugin/search';
  import error from '@/mixins/plugin/error';
  import nav from '@/mixins/nav/point';
  //contants
  import { StatusConstants } from '@/constants/status';
  import { EventConstants } from '@/constants/event';
  //helpers
  import { formatLocalString } from '@/helpers/formatData';

  export default {
    name: 'EventManualPointModal',
    data() {
      return {
        selectedUsers: {
          allFlag: false,
          ids: [],
        },
        searchAction: 'shop/getShopList',
        resetOptions: 'shop/RESET_SHOP_LIST',
        searchField: 'name',
        params: {
          status: StatusConstants.shop.approved.value,
          validFlag: true,
          openFlag: true,
          directory: this.$route.params.directory
        },
        form: {},
        manualPointModal: false,
        checkManualPointModal: false,
        paymentPointStore: null
      }
    },
    props: {
      type: {
        type: String,
        required: true,
        default: '',
      },
      target: {
        type: Object,
        required: true,
        default: () => {},
      },
      searchConditions: {
        type: Object,
        default: () => {},
      },
      totalUserSelected: {
        type: Number,
      },
      pointStore: [Object, null],
      pointMaster: {
        type: Number,
      }
    },
    computed: {
      ...mapGetters({
        infor: 'auth/infor',
        hasShopRole: 'auth/hasShopRole',
        shopList: 'shop/shopList',
        isClientChild: 'auth/isClientChild'
      }),
      storePaymentType() {
        return StatusConstants.manualPoint.payment.store.value;
      },
      cancelStorePaymentType() {
        return StatusConstants.manualPoint.payment.cancelPointStore.value;
      },
      isPaymentType() {
        return this.type === StatusConstants.currencyType.payment;
      },
      isChargeType() {
        return this.type === StatusConstants.currencyType.charge;
      },
      isCancelPayment() {
        return [StatusConstants.currency.parent.payment.cancel.value, StatusConstants.currency.child.payment.cancel.value].includes(this.form.type);
      },
      typeData() {
        let data = Object.values(StatusConstants.manualPoint[this.type]);
        return data.filter(e => {
          const hasShopRoleAndStoreLabel = (!this.hasShopRole && e.label === StatusConstants.manualPoint.charge.store.label);
          const hasTotalUsersAndStoreLabel = (this.totalUserSelected > 1 &&
            (e.label === StatusConstants.manualPoint.payment.cancelPoint.label || e.label === StatusConstants.manualPoint.payment.cancelPointStore.label));
          const hasShopRoleAndCancelStoreLabel = (!this.hasShopRole &&
            (e.label === StatusConstants.manualPoint.payment.store.label || e.label === StatusConstants.manualPoint.payment.cancelPointStore.label));
          return !hasShopRoleAndStoreLabel && !hasShopRoleAndCancelStoreLabel && !hasTotalUsersAndStoreLabel;
        }).map(item => {
          const newItem = { ...item };
          if (newItem.label === StatusConstants.manualPoint.payment.cancelPoint.label) {
            const pointMaster = (this.totalUserSelected === 1) ? '(最大ポイント：' + formatLocalString(this.pointMaster) + 'P)' : '';
            newItem.label = newItem.label + pointMaster;
          } else if (newItem.label === StatusConstants.manualPoint.payment.cancelPointStore.label) {
            const pointStore = this.isShowPaymentPointStore ? '(最大ポイント：' + formatLocalString(this.paymentPointStore) + 'P)' : '';
            newItem.label = newItem.label + pointStore;
          }
          return newItem;
        });
      },
      searchKeyLength() {
        return EventConstants.SEARCH_KEY_MIN_LENGTH;
      },
      storeManualType() {
        return StatusConstants.manualPoint[this.type]?.store.value;
      },
      cancelPointType() {
        return StatusConstants.manualPoint.payment.cancelPoint.value;
      },
      cancelPointStoreType() {
        return StatusConstants.manualPoint.payment.cancelPointStore.value;
      },
      isShowPaymentPointStore() {
        return this.totalUserSelected === 1 && this.paymentPointStore !== null && this.form.type === this.cancelPointStoreType;
      }
    },
    mixins: [modal, search, error, nav],
    components: {
      NumericKeypad,
      SearchSelect,
      FormRow,
      Modal,
    },
    watch: {
      target(val) {
        this.form = {
          ...this.form,
          target: val.ids,
          allFlag: val.allFlag,
        };
      }
    },
    validations() {
      const form = {
        type: { selectRequired: required },
      };
      if (this.form.type === this.storePaymentType) {
        form.shopId = { selectRequired: required };
      } else if (this.form.type === this.cancelStorePaymentType) {
        form.cancelShopId = { selectRequired: required };
      }
      if (this.isPaymentType) {
        if ((this.form.type === this.cancelPointType) && this.pointMaster >= 0) {
          form.amount = { required, minAmountPoint: between(1, this.pointMaster) };
        } else if ((this.form.type === this.cancelStorePaymentType) && this.paymentPointStore >= 0) {
          form.amount = { required, minAmountPoint: between(1, this.paymentPointStore) };
        } else {
          form.amount = { required, between: between(1, 999999) };
        }
      } else if (this.isChargeType) {
        form.added = { required, between: between(1, 999999) };
      }
      return { form }
    },
    methods: {
      checkStoreOrCancel(item) {
        const formType = this.form.type;
        return (item.value === this.storeManualType) ? (formType !== this.storeManualType) : (formType !== this.cancelPointStoreType);
      },
      initForm() {
        this.form = {
          target: this.target?.ids || [],
          allFlag: this.target?.allFlag || false,
          searchCondition: this.searchConditions || {},
          type: '',
          shopId: '',
          cancelShopId: ''
        }
      },
      handleSelectedShop(item) {
        if (this.form.type === this.cancelStorePaymentType) {
          this.form.cancelShopId = item?.id;
          this.paymentPointStore = this.pointStore?.[item?.id] || 0;
        } else {
          this.form.shopId = item?.id;
        }
      },
      handeClearModalAndDataForm() {
        this.paymentPointStore = null;
        this.initForm();
        this.closeModal('checkManualPointModal');
        this.closeModal('manualPointModal');
      },
      handleCloseModal() {
        this.closeModal('checkManualPointModal');
        this.closeModal('manualPointModal');
      },
      formatData() {
        if (this.isPaymentType) {
          this.form = {
            ...this.form,
            amount: this.$refs.keypad.number,
          }
        } else if (this.isChargeType) {
          this.form = {
            ...this.form,
            added: this.$refs.keypad.number,
          }
        }
      },
      onChangeType() {
        this.paymentPointStore = null;
        this.errors.shopId = false;
        this.errors.cancelShopId = false;
        this.resetSearchSelect();
      },
      resetSearchSelect() {
        if (this.$refs?.searchSelectCancelShop?.length && this.$refs?.searchSelectShop?.length) {
          this.$refs.searchSelectCancelShop[0].resetSelection();
          this.$refs.searchSelectShop[0].resetSelection();
          this.form.cancelShopId = '';
          this.form.shopId = '';
        } else if (!this.$refs?.searchSelectCancelShop?.length && this.$refs?.searchSelectShop?.length) {
          this.$refs.searchSelectShop[0].resetSelection();
          this.form.shopId = '';
        } else if (this.$refs?.searchSelect?.length) {
          this.$refs.searchSelect[0].resetSelection();
          this.form.shopId = '';
        }
      },
      submit() {
        this.formatData();
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.getErrors(this.$v.form, 'pointManual');
        } else {
          if (this.totalUserSelected > 1) {
            this.showModal('checkManualPointModal');
          } else {
            this.handleManual();
          }
        }
      },
      async handleManual() {
        let result;
        if (this.isPaymentType) {
          result = await this.$store.dispatch(`${this.storeModule}/createPaymentManual`, this.form);
        } else if (this.isChargeType) {
          result = await this.$store.dispatch(`${this.storeModule}/createChargeManual`, this.form);
        }
        if (result) {
          const cancelType = (this.isPaymentType && this.isCancelPayment) ? this.form.type : null;
          this.handeClearModalAndDataForm();
          this.$emit('setImportingCsv', result.data.id, this.type);
          this.$message.showMessageSuccess(result.data.message);
          this.$emit('checkImportManualStatus', result.data.id, (this.type === 'charge') ? 'charge' : 'payment_point', cancelType);
        }
      },
    },
  }
  </script>
